import { AppAction } from '../types';
import {
  AuthRegister,
  AuthUserProfile,
  ChangePasswordQueryData,
  MyBookings,
  RegisterQueryData,
} from './authTypes';

export const INIT_USER = 'INIT_USER';
export const FETCH_REGISTER_DATA = 'FETCH_REGISTER_DATA';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_REGISTER_QUERY_DATA = 'SET_REGISTER_QUERY_DATA';
export const SET_CHANGE_PASSWORD_QUERY_DATA = 'SET_CHANGE_PASSWORD_QUERY_DATA';
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL';
export const SET_RESET_PASSWORD_DATA = 'SET_RESET_PASSWORD_DATA';
export const SET_MY_PROFILE = 'SET_MY_PROFILE';
export const UPDATE_MY_PROFILE = 'UPDATE_MY_PROFILE';
export const SET_MY_BOOKINGS = 'SET_MY_BOOKINGS';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const MOCK_LOGIN = 'MOCK_LOGIN';

export interface clearUserDataActionType {
  type: typeof CLEAR_USER_DATA;
}

export interface fetchRegisterDataActionType {
  type: typeof FETCH_REGISTER_DATA;
  payload: AuthRegister;
}

export interface initUserActionType {
  type: typeof INIT_USER;
}

export interface loginActionType {
  type: typeof LOGIN;
  payload: AuthUserProfile;
}

export interface mockLoginActionType {
  type: typeof MOCK_LOGIN;
}

export interface setMyProfileActionType {
  type: typeof SET_MY_PROFILE;
  payload: AuthUserProfile;
}

export interface setMyBookingsActionType {
  type: typeof SET_MY_BOOKINGS;
  payload: MyBookings[];
}

export interface logoutActionType {
  type: typeof LOGOUT;
}

export interface setRegisterQueryDataActionType {
  type: typeof SET_REGISTER_QUERY_DATA;
  payload: RegisterQueryData;
}

export interface setChangePasswordQueryDataActionType {
  type: typeof SET_CHANGE_PASSWORD_QUERY_DATA;
  payload: ChangePasswordQueryData;
}

export interface setResetPasswordEmailActionType {
  type: typeof SET_RESET_PASSWORD_EMAIL;
  payload: { email: string };
}

export interface setResetPasswordDataActionType {
  type: typeof SET_RESET_PASSWORD_DATA;
  payload: { email: string; code: string };
}

export interface UpdateMyProfileActionType {
  type: typeof UPDATE_MY_PROFILE;
  payload: AuthUserProfile;
}

export type AuthAction =
  | initUserActionType
  | fetchRegisterDataActionType
  | loginActionType
  | logoutActionType
  | setRegisterQueryDataActionType
  | setResetPasswordEmailActionType
  | setMyProfileActionType
  | UpdateMyProfileActionType
  | setChangePasswordQueryDataActionType
  | setMyBookingsActionType
  | clearUserDataActionType
  | setResetPasswordDataActionType
  | mockLoginActionType;

export type AuthThunkAction<T = void> = AppAction<AuthAction, T>;
