import { AppAction } from '../types';
import { TableExamplePhoto } from './TableExampleTypes';

export const SET_PHOTOS_DATA = 'SET_PHOTOS_DATA';

export interface SetPhotosActionType {
  type: typeof SET_PHOTOS_DATA;
  payload: { photos: TableExamplePhoto[] };
}

export type TableExampleActions = SetPhotosActionType;

export type TableExampleThunkAction<T = void> = AppAction<
  TableExampleActions,
  T
>;
