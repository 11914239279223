import {
    ADD_MODAL_EXAMPLE_EVENTS_DATA, EDIT_MODAL_EXAMPLE_EVENTS_DATA,
    ModalExampleActions, REMOVE_MODAL_EXAMPLE_EVENTS_DATA,
    SET_MODAL_EXAMPLE_EVENTS_DATA,
} from './ModalExampleActionTypes';
import {ModalExampleState} from "./ModalExampleTypes";

const initialState: ModalExampleState = {
    events: [],
};

export const MODAL_EXAMPLE_NAMESPACE = 'MODAL_EXAMPLE_NAMESPACE';

function modalExampleReducer(
    state = initialState,
    action: ModalExampleActions
): ModalExampleState {
    switch (action.type) {
        case SET_MODAL_EXAMPLE_EVENTS_DATA:
            return {...state, events: action.payload.events};
        case ADD_MODAL_EXAMPLE_EVENTS_DATA:
            return {...state, events: [...state.events, action.payload.event]};
        case REMOVE_MODAL_EXAMPLE_EVENTS_DATA:
            return {...state, events: [...state.events.filter(event => event.id !== action.payload.id)]};
        case EDIT_MODAL_EXAMPLE_EVENTS_DATA:
            return {...state, events: [...state.events.map(event => event.id === action.payload.event.id ? action.payload.event : event)]};
        default:
            return state;
    }
}

export default modalExampleReducer;
