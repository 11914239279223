import {
  AuthAction,
  CLEAR_USER_DATA,
  FETCH_REGISTER_DATA,
  INIT_USER,
  LOGIN,
  MOCK_LOGIN,
  SET_CHANGE_PASSWORD_QUERY_DATA,
  SET_MY_BOOKINGS,
  SET_MY_PROFILE,
  SET_REGISTER_QUERY_DATA,
  SET_RESET_PASSWORD_DATA,
  SET_RESET_PASSWORD_EMAIL,
  UPDATE_MY_PROFILE,
} from './authActionTypes';
import { AuthState } from './authTypes';

const initialState: AuthState = {
  isInit: false,
  myBookings: [],
  mockLogin: false,
};

export const AUTH_NAMESPACE = 'AUTH_NAMESPACE';

function authReducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case FETCH_REGISTER_DATA: {
      return { ...state, register: action.payload };
    }
    case INIT_USER: {
      return { ...state, isInit: true };
    }
    case LOGIN: {
      return { ...state, profile: action.payload };
    }
    case SET_MY_PROFILE: {
      return { ...state, profile: action.payload };
    }
    case SET_MY_BOOKINGS: {
      return { ...state, myBookings: action.payload };
    }
    case SET_REGISTER_QUERY_DATA: {
      return {
        ...state,
        register: { ...state.register, queryData: action.payload },
      };
    }
    case SET_CHANGE_PASSWORD_QUERY_DATA: {
      return {
        ...state,
        changePassword: { ...state.changePassword, queryData: action.payload },
      };
    }
    case SET_RESET_PASSWORD_EMAIL: {
      return {
        ...state,
        resetPassword: { ...state.resetPassword, email: action.payload.email },
      };
    }
    case SET_RESET_PASSWORD_DATA: {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          email: action.payload.email,
          code: action.payload.code,
        },
      };
    }
    case UPDATE_MY_PROFILE: {
      return { ...state, profile: action.payload };
    }
    case CLEAR_USER_DATA: {
      return {
        isInit: false,
        myBookings: [],
        register: state.register,
        mockLogin: false,
      };
    }

    case MOCK_LOGIN: {
      return { ...state, mockLogin: true };
    }
    default:
      return state;
  }
}

export default authReducer;
