import { combineReducers } from 'redux';
import navbar from '../../redux-old/navbar';
import dataTables from '../../views/tables/data-tables/store';
import layout from '../../redux-old/layout';
import todo from '../../views/apps/todo/store';
import { LOGOUT } from '../Auth/authActionTypes';
import users from '../../views/apps/user/store';
import email from '../../views/apps/email/store';
import invoice from '../../views/apps/invoice/store';
import calendar from '../../views/apps/calendar/store';
import ecommerce from '../../views/apps/ecommerce/store';
import authReducer, { AUTH_NAMESPACE } from '../Auth/authReducer';
import permissions from '../../views/apps/roles-permissions/store';

import modalReducer, { MODAL_NAMESPACE } from '../modal/modalReducer';
import breadCrumbsReducer, {
  BREAD_CRUMBS_NAMESPACE,
} from '../BreadCrumbs/breadCrumbsReducer';
import tableExampleReducer, {
  TABLE_EXAMPLE_NAMESPACE,
} from '../TableExample/TableExampleReducer';
import modalExampleReducer, {
  MODAL_EXAMPLE_NAMESPACE,
} from '../ModalExample/ModalExampleReducer';

const appReducer = combineReducers({
  [TABLE_EXAMPLE_NAMESPACE]: tableExampleReducer,
  [MODAL_EXAMPLE_NAMESPACE]: modalExampleReducer,

  //Compie API
  [AUTH_NAMESPACE]: authReducer,
  [MODAL_NAMESPACE]: modalReducer,
  [BREAD_CRUMBS_NAMESPACE]: breadCrumbsReducer,

  //Template redux-old
  todo,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
