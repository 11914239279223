import { TableExampleState } from './TableExampleTypes';
import {
  SET_PHOTOS_DATA,
  TableExampleActions,
} from './TableExampleActionTypes';

const initialState: TableExampleState = {
  photos: [],
};

export const TABLE_EXAMPLE_NAMESPACE = 'TABLE_EXAMPLE_NAMESPACE';

function tableExampleReducer(
  state = initialState,
  action: TableExampleActions
): TableExampleState {
  switch (action.type) {
    case SET_PHOTOS_DATA:
      return { ...state, photos: action.payload.photos };
    default:
      return state;
  }
}

export default tableExampleReducer;
