import {AppAction} from '../types';
import {ModalExampleEvent} from './ModalExampleTypes';

export const SET_MODAL_EXAMPLE_EVENTS_DATA = 'SET_MODAL_EXAMPLE_EVENTS_DATA';
export const ADD_MODAL_EXAMPLE_EVENTS_DATA = 'ADD_MODAL_EXAMPLE_EVENTS_DATA';
export const REMOVE_MODAL_EXAMPLE_EVENTS_DATA = 'REMOVE_MODAL_EXAMPLE_EVENTS_DATA';
export const EDIT_MODAL_EXAMPLE_EVENTS_DATA = 'EDIT_MODAL_EXAMPLE_EVENTS_DATA';

export interface SetModalExampleEventsActionType {
    type: typeof SET_MODAL_EXAMPLE_EVENTS_DATA;
    payload: { events: ModalExampleEvent[] };
}

export interface AddModalExampleEventsActionType {
    type: typeof ADD_MODAL_EXAMPLE_EVENTS_DATA;
    payload: { event: ModalExampleEvent };
}

export interface RemoveModalExampleEventsActionType {
    type: typeof REMOVE_MODAL_EXAMPLE_EVENTS_DATA;
    payload: { id: number };
}

export interface EditModalExampleEventsActionType {
    type: typeof EDIT_MODAL_EXAMPLE_EVENTS_DATA;
    payload: { event: ModalExampleEvent };
}

export type ModalExampleActions =
    SetModalExampleEventsActionType
    | AddModalExampleEventsActionType
    | RemoveModalExampleEventsActionType
    | EditModalExampleEventsActionType;

export type ModalExampleThunkAction<T = void> = AppAction<ModalExampleActions,
    T>;
