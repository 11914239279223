import { ModalState } from './modalTypes';
import {
  CLOSE_MODAL,
  ModalAction,
  OPEN_MODAL,
  START_LOADER,
  STOP_LOADER,
} from './modalActionTypes';

const initialState: ModalState = {
  isLoaderActive: false,
};

export const MODAL_NAMESPACE = 'modal';

function modalReducer(state = initialState, action: ModalAction): ModalState {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
        component: action.payload.component,
        position: action.payload.position,
        isFullSizeOnMobile: action.payload.isFullSizeOnMobile,
        modalData: {
          callback: action.payload.callback,
          data: action.payload.data,
          cancelCallback: action.payload.cancelCallback,
        },
      };
    }
    case CLOSE_MODAL: {
      return { ...state, isOpen: false, component: undefined };
    }
    case START_LOADER: {
      return { ...state, isLoaderActive: true };
    }
    case STOP_LOADER: {
      return { ...state, isLoaderActive: false };
    }
    default:
      return state;
  }
}

export default modalReducer;
