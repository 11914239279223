import { AppAction } from '../types';
import { ModalComponentEnum, ModalPosition } from './modalTypes';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export interface openModalActionType {
  type: typeof OPEN_MODAL;
  payload: {
    component: ModalComponentEnum;
    position?: ModalPosition;
    isFullSizeOnMobile?:boolean;
    callback?: (data?: any) => void;
    data?: any;
    cancelCallback?: (data?: any) => void;
  };
}

export interface closeModalActionType {
  type: typeof CLOSE_MODAL;
}

export interface startLoaderActionType {
  type: typeof START_LOADER;
}

export interface stopLoaderActionType {
  type: typeof STOP_LOADER;
}

export type ModalAction =
  | openModalActionType
  | closeModalActionType
  | startLoaderActionType
  | stopLoaderActionType;

export type modalThunkAction<T = void> = AppAction<ModalAction, T>;
